<template>
  <div class="font-medium text-sm text-green-400">
    Alert:
    <FontAwesomeIcon
      :icon="['fad', alert.above ? 'up' : 'down']"
      class="text-gray-200"
      :style="{
        '--fa-secondary-color': colors.gray[300],
        '--fa-secondary-opacity': 0.8,
      }"
    />
    {{ new Intl.NumberFormat().format(alert.threshold)
    }}<span v-html="thresholdColumn"></span>
  </div>
</template>

<script lang="ts" setup>
import colors from "tailwindcss/colors";
import { computed } from "vue";
import { type Alert } from "@/models/alert";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps<{
  alert: Alert;
}>();

const thresholdColumn = computed(() => {
  switch (props.alert.threshold_col) {
    case "temperature":
      return "&deg;";
    case "snow_height":
      return '"';
    default:
      return "&nbsp;" + props.alert.threshold_col;
  }
});
</script>
